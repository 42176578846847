import React from 'react';
import './BtnSuccess.css';
import '../Buttons.css';

interface BtnPrimaryProps {
    OnClickBtn?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    verbose_name: string;
    append_class?: string;
}

const BtnSuccess: React.FC<BtnPrimaryProps> = (props: BtnPrimaryProps) => {
    const classes = ['btn_success', props.append_class].filter(Boolean).join(' ');
    return (
        <button className={classes} type="button" onClick={props.OnClickBtn}>
            {props.verbose_name}
        </button>
    );
};

export default BtnSuccess;
