import React from 'react';
import './Alert.css';
import Popup from '../Popup/Popup';
import { errorType } from '../../Request';
import useOutsideAlerter from '../useOutsideAlerter/useOutsideAlerter';

interface AlertProps {
    title?: string;
    notes?: errorType;
    Fragment?: JSX.Element;
    btns: JSX.Element;
    closeAlert: () => void;
}
const Alert: React.FC<AlertProps> = (props: AlertProps) => {
    const wrapperRef = React.useRef<HTMLDivElement>(null);
    useOutsideAlerter(wrapperRef, props.closeAlert, false, 'dblclick');
    React.useEffect(() => {
        wrapperRef.current?.focus();
    });
    return (
        <Popup>
            <div ref={wrapperRef} tabIndex={0} onKeyDown={(event) => {
                if (event.code === 'Escape') {
                    props.closeAlert();
                }
            }} className={'alert'}>
                {props.title ? <h1 className='alert-titel'> {props.title} </h1> : null}
                <div className="alert-fragment">{props.Fragment ? <React.Fragment>{props.Fragment}</React.Fragment> : null}</div>
                {props.notes ? <div className='notes'>{props.notes.map((note, index) => <p key={index + `${note}`} style={{ overflow: 'auto' }}>{`${note}`}</p>)}</div> : null}
                {props.btns}
            </div>
        </Popup>
    );
};

export default Alert;
