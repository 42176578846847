import React from 'react';
import './Popup.css';


type PopupProps = {
    children: React.ReactNode; // Specify the type of children
};

const Popup: React.FC<PopupProps> = (props: PopupProps) => (
    <div className="popup">
        {props.children}
    </div>
);

export default Popup;