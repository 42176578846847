import React from 'react';
import './Loading.css';
import BtnPrimary from '../Buttons/BtnPrimary/BtnPrimary';
import Popup from '../Popup/Popup';


function cancelLoading(): void {
    window.stop();
}

const Loading: React.FC = () => (
    <>
        <Popup> </Popup>
        <div className="loading"></div>
        <div className="cancel">
            <BtnPrimary verbose_name="الغاء" OnClickBtn={cancelLoading} />
        </div>
    </>
);

export default Loading;
